<template>
    <ConfirmDialog group="dialog" />
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card p-fluid">
                <div class="p-col-12" id="double-text">
                    <h4 id="text-left"><strong>{{ $t('FreeBoard.ViewFreeBoard.Heading') }}</strong></h4>
                    <Button @click="del($route.params.id)" :label="$t('FreeBoard.ViewFreeBoard.delete')"
                        class="p-button-outlined p-button-danger p-mr-2 p-mb-2" icon="pi pi-trash"
                        iconPos="left"></Button>
                </div>
                <div class="p-field p-grid">
                    <label for="name_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('FreeBoard.ViewFreeBoard.Title')
                    }}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ title }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="name_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('FreeBoard.ViewFreeBoard.Description')
                    }}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ description }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="name_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('FreeBoard.ViewFreeBoard.Category')
                    }}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ category_name }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="description_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('FreeBoard.ViewFreeBoard.Country')
                    }}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ country_name }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('FreeBoard.ViewFreeBoard.Status')
                    }}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ status === 'active' ? 'Yes' : 'No' }}</p>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="title" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('FreeBoard.ViewFreeBoard.Images')
                    }}:</label>
                    <div class="p-col-12 p-md-10">
                        <div class="text-red" v-show="render4">{{ $t('validation.invalidFile') }}</div>
                        
                        <img :src="image_path" alt="이미지를 사용할 수 없음" class="product-image" v-if="image_path != ''" />
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{
                        $t('FreeBoard.ViewFreeBoard.PostedDate&Time')
                    }}:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ FormatDate(posted_date) }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-col-12" v-if="comments.length === 0">
            <div class="card p-fluid">
                <h4><strong>{{$t('FreeBoard.ViewFreeBoard.NoComments')}}</strong></h4>
            </div>
        </div>

        <div class="p-col-12" v-else>
            <div style="height: 165px; overflow-y:scroll">
                <div class="card p-fluid" v-for="(item, index) in comments" :key="index">
                    <h4><strong>{{ $t('FreeBoard.ViewFreeBoard.Comments') }}</strong></h4>
                    <div class="p-field p-grid">
                        <label for="name_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0"><img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAC6UlEQVR4nNWXS2wOURTHf62WUmXhlZRWaUNXNgSxQ1Ui7UIEkUhZecRjVxUs8Gm86tFUSIiVjSYSj51HRAWfUiQk4pUgEWFDqkL0K5/c5D/ydTr3zszXSvgnk8zcc885d8458z9n4D9FAVABzAJmAOOB/L/ttAzYBSSBbiDtu3qAW0CDDjdgmAi0Aj+BZ8A+YD5QCRTqMvdVwH7guQ5zGijpr/O1wHfgPjAvhp45YId0V2fjOBc4qVBvAHIs+wYBeRaZ0dkEpIBD2hsZx4FPwFyLfDHQrrT8Ah4Ayx3R+Aw0RXW+Rm9uc75Djs0hFwE1QIvyvtdxCGOzLsx5OfBVYQ/CbL3xsgBZrWS2g28GvoUV5hkVnC3nprKvOvTPA2cddWVSdcqmPFlhdFX7bSDhkNcDjx3yKhWliXQfbNd37sIV4IRDbnjgToiNF8CWIEGHSMYFo/gOGBkgGwa8AnaH2DgAtPkX84EfCpELI4A3wGVgXMb6KOAC8F73LixQGnrxR6X4fArhmAo8BbqA68A14IveflpE/bQa2B/M1GIR0WAithQ4DBwBVgBDIuoWydf0zMU5MQ4wHFgCNAPndDVrrTDbA1Ro0YTHhsHAVtGqCflF4BhwFLgEdErWoL02eOku9hvvcRThGOAm8AFYZ3FQIBr/qL2jLbaqRct9mlhSn4gfQ4F7YrFehWNBsfa2S9ePg8CNIMWdIgk/TH5fA2OJDhOxtypQP17aiKhMaTCdy8Mk8YPpeHFRK11jw0/FhvYD0Qo8VOPwWq+L28PwBNime2PzkQYd5/zXBWzUsymmPf04QGNGvr12PCFMab3CVC3aDR0iHFglGwtlc2VUxRZ9191Z5t9DjWx0xhnJ0ADZJMJozKiJOMiVblq2Yg2lHuqUt6RGLduklIkcDTV3pRs57DaUaIxKqQsmlNPSjB+TUq0ltCelag8tuDgoF4G0yYH/1yyliq93fecDhTxRrvkxNV3N3Nt+UP5t/AZoVK5P6jp21AAAAABJRU5ErkJggg==">{{
                                    item.name === null ? item.email : item.name
                                }}:</label>
                        <div class="p-col-12 p-md-10">
                            <p>{{ FormatDate(item.created_at) }}</p>
                        </div>
                    </div>

                    <div class="p-field p-grid">
                        <div class="p-col-12 p-md-10">
                            <p id="comment">{{ item.comment }}</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div class="card p-fluid">
                <h4><strong>{{ $t('FreeBoard.ViewFreeBoard.Comments') }}</strong></h4>
                <div class="p-field p-grid">
                    <div class="p-col-12 p-md-12">
                        <textarea style="width: 97%; margin-left: 0px; margin-top: 14px"
                            @keydown.space="preventLeadingSpace" :autoResize="true" rows="5" cols="30"
                            v-model="FreeComments" :placeholder="$t('FreeBoard.ViewFreeBoard.CommentsPlace')"
                            maxlength="500"></textarea>
                    </div>
                    <div class="p-d-flex">
                        <Button :label="$t('FreeBoard.ViewFreeBoard.PostComments')"
                            class="p-button-outlined p-mr-2 p-mb-2 p-button-sm" id="Save" icon="pi pi-check"
                            iconPos="left" @click="PostComments()"></Button>
                    </div>
                </div>
            </div>
            <div class="p-d-flex p-jc-end" style="float: left">
                <span v-if="is_admin_post !== '0'">
                    <router-link :to="'/FreeBoardManagement/EditFreeBoard/' + $route.params.id"><Button
                            :label="$t('FreeBoard.ViewFreeBoard.Edit')"
                            class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" icon="pi pi-pencil"
                            iconPos="left">
                        </Button></router-link>
                </span>
                <!-- <Button @click="del($route.params.id)" label="delete" class="p-button-outlined p-button-danger p-mr-2 p-mb-2"><i class="pi pi-trash p-mr-2"></i>{{$t('button.delete')}}</Button> -->
            </div>

            <div class="p-d-flex p-jc-end p-ai-center">
                <div>
                    <Button :label="$t('FreeBoard.ViewFreeBoard.Back')" icon="pi pi-replay" iconPos="left"
                        class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="$router.go(-1)"></Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import FreeBoardService from '../../../service/API/SuperAdmin/FreeBoardService.js';
import { useRoute } from 'vue-router';
import moment from 'moment';
export default {
    data() {
        return {
            bulletinCategoryDetail: {},
            title: '',
            description: '',
            createdDate: '',
            image: '',
            status: '',
            category_name: '',
            country_name: '',
            image_path: '',
            posted_date: '',
            comments: '',
            free_board_id: '',
            comment: '',
            name: '',
            created_at: '',
            FreeComments: '',
            is_admin_post: ''
        };
    },
    created() {
        this.freeBoardService = new FreeBoardService();
    },

    mounted() {
        const route = useRoute();
        console.log(route.params);
        this.UserComments(this.$route.params.id)

        this.freeBoardService.viewFreeBoard(this.$route.params.id).then((res) => {
            this.title = res.data.data[0].title;
            this.posted_date = res.data.data[0].posted_date;
            this.description = res.data.data[0].description;
            this.country_name = res.data.data[0].country_name;
            this.category_name = res.data.data[0].category_name;
            this.status = res.data.data[0].status;
            this.image = res.data.data[0].image;
            this.image_path = res.data.data[0].image_path;
            this.is_admin_post = res.data.data[0].is_admin_post;
            this.comments = res.data.data[0].comments;
            // this.name = res.data.data[0].comments[0].name;
            // this.created_at = res.data.data[0].comments[0].created_at;

            console.log(res);
            console.log(this.comments);
        });
    },
    methods: {
        FormatDate(value) {
            if (value) {
                // return moment(String(value)).format('YYYY/MM/DD  h:mm:ss');
                return moment(String(value)).locale('ko').format('ll - LTS');
            }
        },
        preventLeadingSpace(e) {
            // only prevent the keypress if the value is blank
            if (!e.target.value) e.preventDefault();
            // otherwise, if the leading character is a space, remove all leading white-space
            else if (e.target.value[0] == ' ') e.target.value = e.target.value.replace(/^\s*/, "");
        },
        UserComments(data) {
            this.loading1 = true;
            this.freeBoardService
                .viewFreeBoard(data)
                .then((r) => {
                    this.loading1 = false;
                    this.comments = r.data.data[0].comments;
                    // this.myCallback(1);
                    console.log('details', this.comments);
                })
                .catch((err) => {
                    this.loading1 = false;
                    console.log(err);
                });
        },
        PostComments() {
            // let vcheckData = {
            //     note: this.note,
            //     // type: this.dropdownValueType == null ? '' : 'something',
            // };
            // const { isInvalid, error } = validateJobSeekerNote(vcheckData);
            // if (isInvalid) {
            //     this.error = error;
            //     console.log(error);
            // } else {
            //     console.log(this.formData);
            return axios
                .post('/asnhist-dev/api/v1/admin/free-board/add-comments', { free_board_id: this.$route.params.id, comments: this.FreeComments })
                .then(() => {
                   
                    this.$router.push({ name: 'ViewFreeBoard' });

                    setTimeout(() => {
                        this.$toast.add({ severity: 'success', summary: '업데이트 완료', detail: '댓글이 성공적으로 추가되었습니다.', life: 3000 });
                        window.location.reload();
                    }, 500);
                });
            // }
        },
        del: function (id) {
            console.log(id);
            var self = this;
            this.$confirm.require({
                group: 'dialog',
                header: 'Confirm',
                message: 'Are you sure you want to delete?',
                icon: 'pi pi-trash',
                acceptLabel: "Confirm",
                rejectLabel: "cancellation",
                accept: () => {
                    axios({ method: 'delete', url: '/asnhist-dev/api/v1/admin/free-board/delete', data: { deleteIdArray: id } }).then(function (response) {
                        console.log(response);
                        self.$router.push({ name: 'FreeBoardManagementlist' });
                    });
                    this.$toast.add({ severity: 'info', summary: '삭제되었습니다.', detail: '성공적으로 삭제되었습니다.', life: 3000 });
                },
                // reject: () => {
                //     this.$toast.add({ severity: 'error', summary: 'An error occurred', detail: 'You have rejected', life: 3000 });
                // },

            });

        },
    }
};
</script>

<style scoped>
.p-fluid .p-button {
    width: auto;
}

.p-button {
    background: #000000;
    border: 1px solid #0a0a0a;
    color: white;
}

.product-image {
    padding-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    width: 150px;
    height: 100px;

}

button#Save {
    margin-left: 13px;
    margin-top: 15px;
}

div#double-text {
    display: flex;
    justify-content: space-between;
}

.scroller::-webkit-scrollbar {
    display: none;
}

h4#text-left {
    margin-left: -15px;
}
p#comment {
    margin-left: 30px;
    margin-top: 0px;
}
</style>